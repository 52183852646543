import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toastr } from 'react-redux-toastr'

import { Form } from '@unform/web'
import { cpf, cnpj } from 'cpf-cnpj-validator'

import Alert from '~/components/Alert'
import { Button } from '~/components/Buttons'
import LocationsNear from '~/components/LocationsNear'
import Panel from '~/components/Panel'
import {
  Checkbox,
  FormGroup,
  Input,
  InputPhone,
  Label,
  Radio
} from '~/components/unform'
import InputCpfCnpj from '~/components/unform/Input/InputCpfCnpj'
import TagInput from '~/components/unform/TagInput'
import { getAccountId, history } from '~/helpers'
import AddressFields from '~/pages/locations/_elements/FormLocation/Address'
import {
  clearCustomerState,
  createCustomer,
  updateCustomer
} from '~/store/modules/customers/actions'

import { optionsGender } from './optionsGender'

const FormCustomer = ({ isCreate = false }) => {
  const { saveCustomersLoading: loading, customer } = useSelector(
    state => state.customers
  )

  const dispatch = useDispatch()

  const [tags, setTags] = useState(customer?.metadata?.tags || [])

  const formRef = useRef(null)

  useEffect(() => {
    if (isCreate && !!customer) {
      dispatch(clearCustomerState())
    }
  }, [customer, dispatch, isCreate])

  const handleCreateTag = (values: string[]) => {
    setTags(values)
  }

  const handleDeleteTag = index => {
    setTags(tags => tags?.filter((_, i) => index !== i))
  }

  /**
   * Handle submit
   */

  const handleSubmit = useCallback(
    data => {
      const { social_id } = data

      const socialIdIsValid = cpf.isValid(social_id) || cnpj.isValid(social_id)

      if (!socialIdIsValid) {
        toastr.error('Erro', 'O CPF/CNPJ precisa ser um documento válido.')
        return
      }

      const body = {
        ...customer,
        ...data,
        main_phone: data.main_phone.numAsString,
        metadata: {
          tags
        }
      }

      const action = isCreate ? createCustomer : updateCustomer
      const onSuccessCreate = ({ data }: { data: ResponseCreateCustomer }) => {
        history.push(`/customers/edit/${data.id}`)
      }

      const onSuccess = isCreate ? onSuccessCreate : null

      dispatch(action({ body, onSuccess }))
    },
    [customer, dispatch, isCreate, tags]
  )

  return (
    <Form
      ref={formRef}
      onSubmit={handleSubmit}
      initialData={isCreate ? { use_bot: true } : customer}
    >
      {customer?.is_blocked && (
        <Alert active>Este cliente está bloqueado.</Alert>
      )}

      <fieldset>
        {customer && (
          <Panel className="form-grid">
            <LocationsNear address={customer} />
          </Panel>
        )}
        <Panel className="form-grid">
          <FormGroup className="span2">
            <Label htmlFor="name" isRequired>
              Nome
            </Label>
            <Input
              id="name"
              name="name"
              placeholder="Insira o nome do cliente"
              autoFocus
              required
            />
          </FormGroup>

          {getAccountId() !== 'be56d620-019c-11eb-883d-8980b7e89d71' && (
            <>
              <FormGroup className="span2">
                <Label htmlFor="social_id" isRequired>
                  CPF/CNPJ
                </Label>
                <InputCpfCnpj
                  id="social_id"
                  name="social_id"
                  placeholder="Insira o CPF/CNPJ do cliente"
                  initialData={customer?.social_id}
                  required
                />
              </FormGroup>
            </>
          )}

          <FormGroup className="span2">
            <Label htmlFor="main_email" isRequired>
              E-mail
            </Label>
            <Input
              id="main_email"
              name="main_email"
              placeholder="Insira o e-mail do cliente"
              type="email"
              required
            />
          </FormGroup>

          <FormGroup className="span2">
            <Label htmlFor="main_phone">Telefone</Label>
            <InputPhone
              id="main_phone"
              name="main_phone"
              placeholder="Insira o telefone do cliente"
              type="tel"
            />
          </FormGroup>

          {/* <FormGroup className="span2">
            <Label htmlFor="external_id" isRequired>
              ID Externo
            </Label>
            <Input
              id="external_id"
              name="external_id"
              placeholder="Insira o id externo-mail do cliente"
              required
            />
          </FormGroup> */}

          <FormGroup className="span2">
            <Label htmlFor="date_of_birth" isRequired>
              Data de nascimento
            </Label>
            <Input
              id="date_of_birth"
              name="date_of_birth"
              placeholder="Insira a data de nascimento do cliente"
              type="date"
              required
            />
          </FormGroup>

          <FormGroup className="span2">
            <Label htmlFor="coment">Comentário</Label>
            <Input
              id="comment"
              name="comment"
              placeholder="Insira algum comentário sobre cliente"
            />
          </FormGroup>

          <FormGroup className="span-full">
            <Label htmlFor="gender">Sexo</Label>
            <Radio options={optionsGender} name="gender" layout="grid" />
          </FormGroup>
        </Panel>
      </fieldset>

      <fieldset>
        <Panel>
          <FormGroup className="span2">
            <Checkbox
              name="is_blocked"
              label="Cliente bloqueado"
              id="is_blocked"
            />
          </FormGroup>
        </Panel>

        <Panel>
          <FormGroup className="span2">
            <Checkbox
              name="loyalty_program"
              label="Cliente fidelidade"
              id="loyalty_program"
            />
          </FormGroup>
        </Panel>
        <Panel>
          <FormGroup className="span2">
            <Checkbox
              name="use_bot"
              label="Usar atendimento virtual (BOT) com esse cliente no chat."
              id="use_bot"
            />
          </FormGroup>
        </Panel>
      </fieldset>

      <fieldset>
        <Panel title="Tags">
          <FormGroup className="span2">
            <TagInput
              name="metadata.tags"
              placeholder="Digite uma tag..."
              formRef={formRef}
              onCreate={handleCreateTag}
              onDelete={handleDeleteTag}
              tags={tags}
              helperText="Escreva uma palavra-chave para classificar clientes. Ex.: Fraude"
            />
          </FormGroup>
        </Panel>
      </fieldset>

      <fieldset>
        <Panel title="Inscrições" className="form-grid">
          <FormGroup className="span2">
            <Checkbox
              name="opt_in_whatsapp"
              label="WhatsApp"
              id="opt_in_whatsapp"
            />
          </FormGroup>

          <FormGroup className="span2">
            <Checkbox name="opt_in_email" label="E-mail" id="opt_in_email" />
          </FormGroup>

          <FormGroup className="span2">
            <Checkbox
              name="opt_in_push"
              label="Notificações push"
              id="opt_in_push"
            />
          </FormGroup>

          <FormGroup className="span2">
            <Checkbox name="opt_in_sms" label="SMS" id="opt_in_sms" />
          </FormGroup>
        </Panel>
      </fieldset>

      <AddressFields formRef={formRef} labelItem="do cliente" hideCoordinates />

      <div className="form-default__buttons">
        <Button
          type="submit"
          text="Salvar"
          loading={loading}
          size="large"
          template="success"
        />
      </div>
    </Form>
  )
}

export default FormCustomer
